import React from 'react'

const Map: React.FC = () => {
    return (
        <iframe
            style={{ border: 0 }}
            height="450"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6482.5302911212475!2d139.7098847!3d35.6704728!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188ca2f66739b1%3A0x314343a79fb271b2!2z44CSMTUwLTAwMDEg5p2x5Lqs6YO95riL6LC35Yy656We5a6u5YmN77yT5LiB55uu77yR77yW4oiS77yR77yW!5e0!3m2!1sja!2sjp!4v1587174489464!5m2!1sja!2sjp"
            width="100%"
            loading="lazy"
            title="miss platinum表参道ショールームへのアクセス"
        />
    )
}

export default Map
